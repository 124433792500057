import React from 'react'
import "../styles/termsandconditions.css";

const TermsAndConditions = () => {
  return (
    <div class="termsandconditions-root">
      <div class="termsandconditions-container">
        <h2>Terms & Conditions</h2>
        
        <h4>EARLY ARRIVAL & LATE DEPARTURES:</h4>
        <p>If your arrival is early in the day and you prefer immediate access to your room, we recommend reserving the room for the prior night to guarantee immediate access. Similarly, for late departures, reserving the room for an additional night will guarantee access until you leave the hotel. If you choose and wish not to reserve, we will be glad to store your bags and make our fitness facilities available for you to freshen up.</p>
        
        <h4>GUARANTEED RESERVATIONS:</h4>
        <p>A guaranteed reservation assures you a room even if you check-in late (after 06:00pm). A one night deposit is required for all bookings. All reservations made through the web site must be guaranteed by a credit card.</p>
        
        <h4>CANCELLATIONS OF BOOKING:</h4>
        <p>Cancellations for a hotel room reservation must be received 48 hours prior to the expected day of arrival. If cancellation of a guaranteed reservation is not received by the required date, the hotel will charge for one night's accommodation.</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
