import React, { lazy, Suspense } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';

import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';

const Actionbar = lazy(() => import('./Actionbar'));
const Navbar = lazy(() => import('./Navbar'));
const Footer = lazy(() => import('./Footer'));

const Home = lazy(() => import('./Home'));
const ListProperty = lazy(() => import('./ListProperty'));
const Property = lazy(() => import('./Property'));

export default class App extends React.Component {
    render() {
        return (
            <Router>
                <Suspense>
                    <Actionbar />
                </Suspense>
                
                <Suspense>
                    <Navbar />
                </Suspense>

                <Routes>
                    <Route exact path="/" element={
                        <Suspense>
                            <Home />
                        </Suspense>
                    }/>
                    <Route exact path="/:propertyId" element={
                        <Suspense>
                            <Property />
                        </Suspense>
                    }/>

                    <Route exact path="/list-property" element={
                        <Suspense>
                            <ListProperty />
                        </Suspense>
                    }/>
                    
                    <Route path="/terms-and-conditions" element={
                        <Suspense fallback={<div>Loading...</div>}>
                            <TermsAndConditions />
                        </Suspense>
                    } />
                    
                    <Route path="/privacy-policy" element={
                        <Suspense fallback={<div>Loading...</div>}>
                            <PrivacyPolicy />
                        </Suspense>
                    } />
                </Routes>

                <Footer />
            </Router>
        )
    }
}
