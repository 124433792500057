import React from 'react'
import "../styles/privacypolicy.css";

const PrivacyPolicy = () => {
  return (
    <div class="privacypolicy-root">
      <div class="privacypolicy-container">
        <h2>Privacy Policy</h2>
        <p>Welcome to <strong>The Udaipur Hotel's Website</strong> ("the Website"), an online platform for hotel room reservations. This Privacy Policy is designed to inform you about the types of information we collect, how we use that information, and the choices you have regarding your information. Please read this Privacy Policy carefully to understand our practices regarding your personal data.</p>

        <h3>Information we collect:</h3>
        <ul>
          <li>Personal Information:
            <ul>
              <li>Name</li>
              <li>Contact information (email address, phone number)</li>
              <li>Billing information</li>
              <li>Address</li>
            </ul>
          </li>
          <li>Reservation Information:
            <ul>
              <li>Check-in and check-out dates</li>
              <li>Room preferences</li>
              <li>Special requests</li>
            </ul>
          </li>
          <li>Device and Usage Information:
            <ul>
              <li>IP address</li>
              <li>Browser type</li>
              <li>Device type</li>
              <li>Pages visited</li>
            </ul>
          </li>
        </ul>
        
        <h3>How we use your information:</h3>
        <ul>
        <li>Reservation Processing:
        <ul>
        <li>To process and manage your hotel room reservations.</li>
        </ul>
        </li>
        <li>Communication:
        <ul>
        <li>To send reservation confirmations, updates, and important information related to your booking.</li>
        </ul>
        </li>
        <li>Customer Support:
        <ul>
        <li>To provide customer support and respond to inquiries.</li>
        </ul>
        </li>
        <li>Improvements and Analytics:
        <ul>
        <li>To analyze website usage patterns and improve our services.</li>
        </ul>
        </li>
        <li>Marketing:
        <ul>
        <li>With your consent, we may send promotional materials and offers.</li>
        </ul>
        </li>
        </ul>
        <h3>Sharing of Information:</h3>
        <ul>
        <li>Service Providers:
        <ul>
        <li>We may share your information with third-party service providers who assist in reservation processing, customer support, and analytics.</li>
        </ul>
        </li>
        <li>Legal Compliance:
        <ul>
        <li>We may disclose your information to comply with legal obligations or protect our rights.</li>
        </ul>
        </li>
        <li>Business Transfers:
        <ul>
        <li>In the event of a merger, acquisition, or sale, your information may be transferred to the acquiring entity.</li>
        </ul>
        </li>
        </ul>
        <h3>Your Choices:</h3>
        <ul>
        <li>Opt-out:
        <ul>
        <li>You can opt-out of receiving promotional emails by following the unsubscribe instructions provided in the email.</li>
        </ul>
        </li>
        <li>Access and Correction:
        <ul>
        <li>You can access and update your personal information by logging into your account or contacting us.</li>
        </ul>
        </li>
        <li>Cookies:
        <ul>
        <li>You can manage your cookie preferences through your browser settings.</li>
        </ul>
        </li>
        </ul>
        <h3>Security:</h3>
        <p>We implement security measures to protect your information from unauthorized access, disclosure, alteration, and destruction.</p>
        <h3>Children's Privacy:</h3>
        <p>Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children.</p>
        <h3>Changes to the Privacy Policy:</h3>
        <p>We may update this Privacy Policy to reflect changes in our practices. Please review it periodically.</p>
        <h3>Contact Us:</h3>
        <p>If you have questions or concerns about this Privacy Policy, please contact us at <a href="mailto:theudaipurhotels@gmail.com">theudaipurhotels@gmail.com</a> ; <a href="tel:+91-8560889994">+91-8560889994</a> ; <a href="tel:+91-9079045689">+91-9079045689</a></p>
        <p>By using our Website, you consent to the terms of this Privacy Policy.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
